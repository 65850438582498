body {
  margin: 0;
  // height: 100%;
  // font-family: Montserrat;
  background-color: #f5f5fa;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5fa;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 10px;
    background-color: #e9e8e8;
  }
}
body {
  scrollbar-width: thin;
}

// Bar ============================================
.blue-loader {
  border-radius: 60px;
  overflow: hidden;
  width: 100%;
  margin-top: -10px;
  span {
    display: block;
  }
  .bar {
    background: rgba(0, 0, 0, 0.075);
    .progress {
      animation: loader 4s ease infinite;
      // Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating.
      background: #8b7df2;
      color: #fff;
      padding: 2px;
      width: 0;
      height: 2px;
    }
  }
}

// Animation =============================================
@keyframes loader {
  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;
  }

  94% {
    width: 86%;
  }

  100% {
    width: 100%;
  }
}

